import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { useI18n } from 'services/i18n';
import media from 'styles/media';
import { Background, Col, Container, Hidden, Row } from 'styles/grid';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Block from 'components/block';
import SwitcherBlock from 'components/switcher-block';
import NonStretchedImage from 'components/non-stretched-image.js';
import Links from 'components/links';
import Shop from 'images/icons/shop.svg';
import Check from 'images/icons/check.svg';
import {
  PrimaryTitle,
  SecondaryTitle,
  BiggerText,
  BigText,
  ParagraphTitle,
  ParagraphText,
} from 'components/texts';
import { DemoLink } from 'components/buttons';

const ImageSectionContainer = styled(Container)`
  padding: 60px 0;

  ${media.md`
    padding: 100px 0;
   
  `}
`;

const ImageContainer = styled.div`
  margin-top: 60px;
  ${media.md`
    
      margin-top: 0px;
    
  `}
`;

const MainIconRow = styled(Row)`
  margin-bottom: 40px;
  ${media.md`
    margin-bottom: 60px;
  `}
`;

const ListItem = styled(BigText)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  svg {
    /* height: 16px; */
    width: 24px;
    flex: 0 0 24px;
    margin-right: 1em;
  }
`;

const RetailPage = () => {
  const [t, language] = useI18n();
  const data = useStaticQuery(graphql`
    query {
      fr: allFile(filter: { relativeDirectory: { eq: "retail/fr" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
      en: allFile(filter: { relativeDirectory: { eq: "retail/en" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  `);

  const images = {
    fr: data.fr.nodes.reduce((mapping, image) => {
      mapping[image.name] = image.childImageSharp;
      return mapping;
    }, {}),
    en: data.en.nodes.reduce((mapping, image) => {
      mapping[image.name] = image.childImageSharp;
      return mapping;
    }, {}),
  };

  return (
    <Layout>
      <SEO
        title={t('seo.title.retail')}
        lang={language}
        description={t('seo.description.retail')}
      />
      <section>
        <Container>
          <MainIconRow justify="center">
            <Col md="content" style={{ textAlign: 'center' }}>
              <Shop height="4rem" width="4rem" />
            </Col>
          </MainIconRow>
          <Row justify="center">
            <Col md="content">
              <PrimaryTitle align="center">{t('retail.title')}</PrimaryTitle>
            </Col>
          </Row>
          <Hidden md lg xl>
            <Row justify="center">
              <Col style={{ marginTop: 40 }} sm="content">
                <DemoLink id="ga-demo-plugin">{t('index.appointment')}</DemoLink>
              </Col>
            </Row>
          </Hidden>
        </Container>
      </section>
      <Background>
        <section>
          <ImageSectionContainer>
            <Row justify="space-around" align="center">
              <Col sm={12} md={5} orderMd={2}>
                <BiggerText weight="bold">{t('retail.solution')}</BiggerText>
                <ListItem>
                  <Check />
                  {t('retail.argument1')}
                </ListItem>
                <ListItem>
                  <Check />
                  {t('retail.argument2')}
                </ListItem>
                <ListItem>
                  <Check />
                  {t('retail.argument3')}
                </ListItem>
              </Col>
              <Col md={5} orderMd={1}>
                <ImageContainer>
                  <NonStretchedImage
                    fluid={images[language].comfort.fluid}
                    alt={t('blog.user-voice-sessions.imageAlt')}
                    style={{ maxHeight: '100%', objectFit: 'contain' }}
                  />
                </ImageContainer>
              </Col>
            </Row>
          </ImageSectionContainer>
        </section>
      </Background>
      <section>
        <Container>
          <Row>
            <Col>
              <SecondaryTitle>{t('retail.subtitle')}</SecondaryTitle>
            </Col>
          </Row>
          <SwitcherBlock
            mainTitle={t('retail.recommendation')}
            titleOne={t('retail.scan')}
            textOne={t('retail.phone')}
            titleTwo={t('retail.clients')}
            textTwo={t('retail.tablet')}
            imageFluidOne={images[language].phone_retail.fluid}
            imageFluidTwo={images[language].tablet_retail.fluid}
            altOne={t('seo.alt.retail.phoneScan')}
            altTwo={t('seo.alt.retail.tabletScan')}
          />

          <Block
            titleText={t('retail.reco')}
            imageFluid={images[language].plugin_screens.fluid}
            isImageLeft={true}
            alt={t('seo.alt.plugin.pluginComfortComparison')}
          >
            <ParagraphTitle weight="bold">{t('retail.size')}</ParagraphTitle>
            <ParagraphText>{t('retail.comfort')}</ParagraphText>
          </Block>

          <Block
            titleText={t('retail.channel')}
            imageFluid={images[language].retail_no_reco_screens.fluid}
            alt={t('seo.alt.retail.reco')}
          >
            <ParagraphTitle weight="bold">{t('retail.line')}</ParagraphTitle>
            <ParagraphText>{t('retail.availability')}</ParagraphText>
          </Block>
        </Container>
      </section>

      <Links withBlockTitle={true} links={['plugin', 'dashboard']} />
    </Layout>
  );
};

export default RetailPage;
